import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DateTime } from 'luxon';
import { HelperService } from '../../services/helper.service';
import { UserSearchResult } from 'src/app/components/usersearchdialog/usersearch.component';
import { SupaService } from 'src/app/services/supa.service';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss'],
})
export class ReportsComponent implements AfterViewInit {

  @ViewChild('ifr') ifr: ElementRef;
  public selectedEmployees:string[]=[];
  selectedEmployeesLabel:string;
  allEmployees: string[];

  constructor(private helper: HelperService, private supa: SupaService) {

  }
  
  ngOnInit(){
    if(localStorage.getItem("selectedEmployees")==null){
      localStorage.setItem("selectedEmployees","")
    }
    this.selectedEmployees = localStorage.getItem("selectedEmployees").split(",")
    this.getEmployees();
    this.setLabel()
  }

  changeEmployees($event,a){
    localStorage.setItem("selectedEmployees",this.selectedEmployees.join(","))
    this.setLabel();
    this.reloadIfr();
  }

  setLabel(){
    switch(this.selectedEmployees.length){
      case 0:
        this.selectedEmployeesLabel="None"
        break;
      case 1:
        this.selectedEmployeesLabel=this.selectedEmployees[0]
        break;
      case this.allEmployees.length:
        this.selectedEmployeesLabel="All"
        break;
      default:
        this.selectedEmployeesLabel=`Multiple (${this.selectedEmployees.length})`
        break;
    }
  }

  async getEmployees(){
    let {data} = await this.supa.supabase.rpc('find_users', {
      eid:  '%%',
      ename:  '%%',
      egroup: '',
    })
    this.allEmployees = await data.map((v:UserSearchResult) => {
      v.permissions = v.permissions.filter(p => !p.startsWith('Osb') && !p.startsWith('default-'))
      return v.fullname;
    })
    this.selectedEmployees=localStorage.getItem("selectedEmployees").split(",").filter(value=>this.allEmployees.includes(value))
  }

  ngAfterViewInit(): void {
    this.reloadIfr();
  }

  startDate = DateTime.now().startOf('month')
  endDate = DateTime.now().endOf('month')

  async setDate(ev) {
    let newdates = await this.helper.chooseDateTime({
      IsRange: true,
      WithTime: false,
      RangeEnd: this.endDate,
      RangeStart: this.startDate
    })
    this.startDate = newdates[0].startOf('day');
    this.endDate = newdates[1].endOf('day');

    this.reloadIfr();
  }

  reloadIfr() {
    if (this.ifr) {
      if (this.ifr.nativeElement) {
        this.ifr.nativeElement.src = `https://osbreport.amr.iq-motive.com/d/s93B5t54k/vhc?orgId=1&kiosk&from=${this.startDate.toMillis()}&to=${this.endDate.toMillis()}&var-employees=${this.selectedEmployees.join(",")}`;
      }
    }
  }

  preventEsc(e) {
    console.log(e)
  }
}
