/* eslint-disable max-len */
import { Component, Input, OnInit } from '@angular/core';
import { AlertController, LoadingController, ModalController } from "@ionic/angular";
import { BehaviorSubject } from 'rxjs';
import { SupaService } from '../../../services/supa.service';
import { HelperService } from '../../../services/helper.service';
import { HttpClient } from '@angular/common/http';
import { DmsCarApiResult, DmsService } from 'src/app/services/dms.service';
import { ConfirmDelete } from 'src/app/decorators/confirmdelete.decorator';
import { DateTime } from 'luxon';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-osbwizard',
    templateUrl: './osbwizard.component.html',
    styleUrls: ['./osbwizard.component.scss'],
})
export class OSBWizardDialog implements OnInit {

    currency = '~';

    currentStep = 0;
    isloading = true;

    step0Data = []; //locations
    step1Data = {additionals:[],services:[],recalls:[]}; //jobs
    step2Data = []; //timeslots

    step0Result: any = -1; // selected location
    step0Vin = '';//'JM7BK226751109109';
    step0PhoneNumber = '';
    step0LicensePlate = '';
    step0Model = '';
    step0CustomerName = '';

    step1Result = []; // selected jobs
    step2Result = ''; // selected timeslot

    selectedService = '0';
    selectedDiagnose = '0';
    selectedAdditionals = [];

    constructor(private modalCtrl: ModalController,
        private supa: SupaService,
        private http: HttpClient,
        private dms: DmsService,
        private alertController: AlertController,
        private helper: HelperService,
        private loadingController: LoadingController) {}

    ngOnInit() {
        this.load0Data();
        this.supa.Settings().then(s => {
            this.currency = s.lang1currency;
        });
        console.log(this.supa.LoginObject.getValue());
    }

    async load0Data() {
        this.isloading = true;
        const locationdata: any = await this.http.get(`${environment.osbUrl}/api/customer/locations`).toPromise();
        this.step0Data = locationdata;

        console.log('Figure location out');
        for (const perm of this.supa.LoginObject.getValue()?.permissions) {
            console.log(perm);
            if (perm.startsWith('Location')) {
                console.log('Is a location');
                const prefloc = perm.substring(8);
                for (const d of locationdata) {
                    console.log(d.locationCode, prefloc);
                    if (d.locationCode === prefloc) {
                        this.step0Result = d;
                        console.log('Jo eem');
                        break;
                    }
                }
                break;
            }
        }

        this.isloading = false;
    }

    async load1Data() {
        console.log(this.step0Result);
        this.isloading = true;
        this.selectedService = '0';
        this.selectedDiagnose = '0';
        if (!this.step0Result.id) { this.goToStep(0); return; };
        const servicedata: any = await this.http.get(`${environment.osbUrl}/api/customer/services?locationid=${this.step0Result.id}&carline=${this.step0Vin.substring(4,7)}&specification=&vin=${this.step0Vin}`).toPromise();
        this.step1Data = servicedata;
        this.isloading = false;
    }

    async load2Data() {
        this.isloading = true;
        const now = new Date();
        const body = {
            fromCustomer: false,
            month: now.getMonth() + 1,
            year: now.getFullYear(),
            slotMinutes: this.step0Result.slotMinutes,
            specificReceptionistId: 'no',
            timeCap: this.step0Result.timeCap,
            jobs: this.step1Result.map(t => ({
                duration: t.service_eng !== undefined ? t.realDuration : t.duration,
                code: t.service_eng !== undefined ? t.realCode : t.operationCode
            })),
        };
        const nowMinutes = now.getHours() * 60 + now.getMinutes();
        const timeslots: any = await this.http.post(this.step0Result.apiUrlAvailability, body).toPromise();
        this.step2Data = timeslots
            .filter(t => t.startsWith(
                body.month.toString().padStart(2, '0') +'/'+
                now.getDate().toString().padStart(2, '0') +'/'+
                body.year
            ))
            .filter(t => ((Number.parseInt(t.substring(11,13), 10) * 60) + Number.parseInt(t.substring(14,16), 10)) >= nowMinutes);
        this.isloading = false;
    }

    async confirmBooking() {
        const loader = await this.helper.getLoader();
        await loader.present();

        const service = this.step1Result.find(s => s.service_eng !== undefined);
        const arrivalDate = new Date(this.step2Result);
        const arrival = Math.round(arrivalDate.getTime() / 1000);
        const jobs = this.step1Result.map(r => ({
            operationCode: r.service_eng !== undefined ? r.realCode : r.operationCode,
            duration: r.service_eng !== undefined ? r.realDuration : r.duration,
            name: r.service_eng !== undefined ? r.service_eng : r.name,
            price: r.service_eng !== undefined ? r.realPrice : r.price
        }));

        const body = {
            appointmentCancelled: false,
            handoverTime: 0,
            brand: 'Mazda',
            cancelDate: null,
            creationTextSent: 0,
            reminderTextSent: 0,
            receptionist: '',
            fromCustomer: false,
            withUser: this.supa.LoginObject.getValue()?.employee_id ? this.supa.LoginObject.getValue()?.employee_id : '',
            hhaService: service ? service.service_eng : 'other',
            kms: service ? service.kms : '0',
            seenFolder: false,
            arrivalTime: arrival,
            arrivalTimeChosen: arrivalDate.getDate().toString().padStart(2, '0') + '-' + (arrivalDate.getMonth()+1).toString().padStart(2, '0')+ '-' +arrivalDate.getFullYear().toString() + ' ' + this.step2Result.substring(11,16),
            customerAddress: '',
            customerCompany: '',
            customerEmail: '',
            customerName: this.step0CustomerName,
            customerPostalCode: '',
            phoneNumber: this.step0PhoneNumber,
            licensePlate: this.step0LicensePlate,
            model: this.step0Model,
            vin: this.step0Vin,
            locationCode: this.step0Result.locationCode,
            jobs,
            language: 'en',
            hhaNote: '(IQVHC) booked with vhc wizard',
            SendClientSMS:false,
            hhaAutoRemark: jobs.map(j => j.name).join(',')
        };

        const result: any = await this.http.post(`${environment.osbUrl}/api/customer/newappointment`, body).toPromise();
        console.log(result);
        loader.dismiss();

        if (result.id) {
            this.helper.notify('Appointment was created, appontment id is ' + result.id);
            this.dismissModal();
        } else {
            this.helper.notify('Appointment was probably not created', 'warning');
        }
    }


    @ConfirmDelete("An appointment already exists. Do you want to delete it?")
    delete(exists){
        const _time = DateTime.utc().toSeconds().toString();
        const left = (Number.parseInt(_time.substr(0,5))*2).toString();
        const right = (Number.parseInt(_time.substring(5))*2).toString();
        let withUser    = ""
        let id          = exists.id
        let code        = "99999"
        let phone       = exists.o
        return this.http.get(`${environment.osbUrl}/api/customer/deleteappointment?idVal=${left}.${id*2}.${right}&withUser=${withUser}&code=${code}&o=${phone}&sendclientsms=${false}`).toPromise();

    }

    async findVehicleDMS() {

        const exconf = await this.supa.ExternalConfig();

        let loading = await this.loadingController.create({
            message: 'Checking DMS for this VIN',
            spinner: 'bubbles',
            backdropDismiss: true
        });
        loading.present();

        if (exconf.name === 'Keyloop' && this.step0Vin.length < 17) {
            const tempkeyloopvehicle: any= await this.supa.supabase.rpc('get_vehicle_partlyvin', {partlyvin: this.step0Vin});
            if (!Array.isArray(tempkeyloopvehicle.data) && (tempkeyloopvehicle.data as any[]).length >= 1) {
                loading.dismiss();
                this.helper.notify('Vehicle is not in the DMS', 'danger');
                return;
            } else {
                this.step0Vin = (tempkeyloopvehicle.data[0] as any).vin;
            }
        }

        loading.message = 'Checking OSB for existing Appointments'
        this.http.get(`${environment.osbUrl}/api/customer/appointmentexists?vin=${this.step0Vin}`).toPromise().then(async x=>{
            if(x['appointmentExists']){
                await this.delete(x);
            }
            if (exconf.name === 'Keyloop') {
                loading.message = 'Checking DMS for vehicle info'
                const keyloopvehicle = await this.supa.supabase.rpc('get_vehicle_info', {vin: this.step0Vin});
                const keyloopvehiclecustomers = await this.supa.supabase.rpc('get_vehicle_customers_info', {vin: this.step0Vin}).single();
                let foundphone = '';
                let foundname = '';
                if (keyloopvehiclecustomers?.data?.relations) {
                    const relation = keyloopvehiclecustomers.data.relations.find(v => v.type === 'DRIVER');
                    if (relation) {
                        const keyloopvehiclecustomer = await this.supa.supabase.rpc('get_vehicle_customer_by_id', {cid: relation.customer.customerId}).single();
                        if (keyloopvehiclecustomer?.data?.customer) {
                            foundname = keyloopvehiclecustomer.data.customer.individual.givenName;
                            if (keyloopvehiclecustomer.data.customer.individual.middleName) {
                                foundname += " " + keyloopvehiclecustomer.data.customer.individual.middleName
                            }
                            foundname += " " + keyloopvehiclecustomer.data.customer.individual.familyName;
                            if (keyloopvehiclecustomer.data.customer.communications.preferredPhone.includes("PERSONAL")) {
                                foundphone = keyloopvehiclecustomer.data.customer.communications.preferredPhone.includes("LANDLINE")?keyloopvehiclecustomer.data.customer.communications.personal.landline:keyloopvehiclecustomer.data.customer.communications.personal.mobile
                            } else {
                                foundphone = keyloopvehiclecustomer.data.customer.communications.preferredPhone.includes("LANDLINE")?keyloopvehiclecustomer.data.customer.communications.work.landline:keyloopvehiclecustomer.data.customer.communications.work.mobile
                            }
                        }
                    }
                }
                this.step0PhoneNumber = foundphone;
                this.step0CustomerName = foundname;
                if ((keyloopvehicle as any)?.body?.identification?.licensePlate) {
                    this.step0LicensePlate = (keyloopvehicle.body as any).identification.licensePlate;
                    this.step0Model = (keyloopvehicle.body as any).baseDetails.description + ' ' + (keyloopvehicle.body as any).baseDetails.exteriorColor;
                    this.step0PhoneNumber = foundphone;
                    this.step0CustomerName = foundname;

                    loading.dismiss();
                } else {
                    loading.dismiss()
                    this.helper.notify('Vehicle is not completely in the DMS', 'danger');
                }
            } else if (exconf.name === 'HHA') {
                const vehicleresult: DmsCarApiResult = await this.dms.getDMS_VEHICLE(this.step0Vin);
                console.log(vehicleresult);
                if (vehicleresult.items.length === 0) {
                    loading.dismiss();
                    this.helper.notify('Vehicle is not in the DMS', 'danger');
                    return false;
                }
                this.step0PhoneNumber = vehicleresult.items[0].phonenumber;
                this.step0LicensePlate = vehicleresult.items[0].licenseplate;
                this.step0Model = vehicleresult.items[0].model;
                this.step0CustomerName = vehicleresult.items[0].customer_name;
            } else {
                loading.dismiss();
                this.helper.notify('DMS search not implemented', 'danger');
            }
        });
    }

    async findVehiclePhone() {
        console.log('findVehiclePhone');

        const loader = await this.helper.getLoader();
        await loader.present();
        const result: any = await this.http.get(`${environment.osbUrl}/api/customer/findcarwithphone?phone=` + this.step0PhoneNumber).toPromise();
        loader.dismiss();

        if (result.length === 0) {
            this.helper.notify('Vehicle is not in the DMS', 'danger');
            return false;
        }
        let idx = 0;
        if (result.length > 1) {
            const alert = await this.alertController.create({
                header: 'Choose vehicle',
                subHeader: 'Please choose the correct vehicle',
                buttons: [
                    ...result.map((r, i) => ({ text: r.model, role: i })),
                    { text: 'Cancel', role: 'cancel' }
                ]
            });
            await alert.present();
            const ans = await alert.onDidDismiss();
            idx = Number.parseInt(ans.role, 10);
        }

        if (Number.isInteger(idx) && idx >= 0 && idx < result.length) {
            this.step0Vin = result[idx].vin;
            this.step0LicensePlate = result[idx].licensePlate;
            this.step0Model = result[idx].model;
        }
    }

    changeService(event: any) {
        const sidx = this.step1Result.findIndex(s => s.service_eng !== undefined);
        if (sidx >= 0) {
            this.step1Result.splice(sidx, 1);
        }
        if (event.detail.value !== '0') {
            this.step1Result.unshift(event.detail.value);
        }
    }

    changeDiagnose(event: any) {
        const sidx = this.step1Result.findIndex(s => s.service_eng === undefined && s.isConcern);
        if (sidx >= 0) {
            this.step1Result.splice(sidx, 1);
        }
        if (event.detail.value !== '0') {
            this.step1Result.push(event.detail.value);
        }
    }

    changeAdditional(event: any) {
        this.step1Result = this.step1Result.filter(s => !(s.service_eng === undefined && !s.isConcern));
        this.step1Result.push(...event.detail.value);
    }

    dismissModal() {
        this.modalCtrl.dismiss();
    }

    stepAvailable(step) {
        if (step === 0) {
            return true;
        }
        if (step === 1) {
            return this.step0Result !== -1 &&
                this.step0Vin !== '' &&
                this.step0PhoneNumber !== '' &&
                this.step0LicensePlate !== '' &&
                this.step0Model !== '' &&
                this.step0CustomerName !== '' &&
                this.stepAvailable(step - 1);
        }
        if (step === 2) {
            return this.step1Result.length > 0 && this.stepAvailable(step - 1);
        }
        if (step === 3) {
            return this.step2Result !== '' && this.stepAvailable(step - 1);
        }
        if (step === 4) {
            return this.stepAvailable(step - 1);
        }
    }

    async goToStep(step: number) {
        if (this.stepAvailable(step)) {
            if (step === 0) {
                this.step1Result = [];
                this.step2Result = '';
            }
            if (step === 1) {
                if (this.currentStep === 0) {
                    const existing: any = await this.http.get(`${environment.osbUrl}/api/customer/appointmentexists?vin=` + this.step0Vin).toPromise();
                    if(existing.appointmentExists) {
                        this.helper.notify('An appointment already exists for this vin', 'danger');
                        return;
                    }
                }
                this.step1Result = [];
                this.step2Result = '';
                this.load1Data();
            }
            if (step === 2) {
                this.load2Data();
            }
            if (step === 4) {
                this.confirmBooking();
                return;
            }
            this.currentStep = step;
        }
    }
}
