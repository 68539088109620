import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoadingController, ModalController } from '@ionic/angular';
import { DateTime } from 'luxon';
import { ConfirmDelete } from '../../decorators/confirmdelete.decorator';
import { vhcrecord } from '../../interfaces/vhcrecord.interface';
import { followupdata, FollowUpItem } from '../../pages/followup/followupitem.interface';
import { HelperService } from '../../services/helper.service';
import { JSettings, SupaService } from '../../services/supa.service';
import { VhcBrakeModule } from '../../vhcmodules/brakemodule/vhcbrakemodule';
import { VhcCross_sellModule } from '../../vhcmodules/cross_sellmodule/cross_sellmodule';
import { VhcQuestionblockModule } from '../../vhcmodules/questionblock/vhcquestionblockmodule';
import { VhcTyreModule } from '../../vhcmodules/tyremodule/vhctyremodule';
import { Vhcmodule } from '../../vhcmodules/vhcmodule';

@Component({
  selector: 'app-followupchoosedialog',
  templateUrl: './followupchoosedialog.component.html',
  styleUrls: ['./followupchoosedialog.component.scss'],
})
export class FollowupchooseDialog implements OnInit {

  @Input('vhcrecord') vhcrecord: vhcrecord
  @Input('premodules') premodules: Vhcmodule[]
  @Input('mpimodules') mpimodules: Vhcmodule[]
  @Input('qimodules') qimodules: Vhcmodule[]

  constructor(private modalController: ModalController, private supa: SupaService, private helper: HelperService, private loadingController: LoadingController, private router: Router) { }

  reportData:ReportData[] = []
  followUpItems: FollowUpItem[] = []

  settings: JSettings = null;

  remarks: ''
  islang1 = true;

  ngOnInit() {
    this.init();
  }

  async init() {
    this.settings = await this.supa.Settings();
    this.followUpItems = [];
    this.appendFollowupItemsFromModules(this.premodules, 'Pre', 'prejp');
    this.appendFollowupItemsFromModules(this.mpimodules, 'Mpi', 'jp');
    this.appendFollowupItemsFromModules(this.qimodules, 'Qi','qi');
    this.followUpItems.sort((a,b)=>a.value.charCodeAt(0) - b.value.charCodeAt(0))
  }

  async doReport() {
    let authredcount = 0;
    let authambercount = 0;
    let authredamount = 0;
    let authamberamount = 0;
    let identifiedredcount = 0;
    let identifiedambercount = 0;
    let identifiedredamount = 0;
    let identifiedamberamount = 0;
    this.reportData.forEach(r => {
      const isred = r.rtype.includes('Red');
      const isauth = r.authorized;
      if (isred) {
        identifiedredcount++;
        identifiedredamount+=r.amount;
        if (isauth) {
          authredcount++;
          authredamount+=r.amount;
        }
      } else {
        identifiedambercount++;
        identifiedamberamount+=r.amount;
        if (isauth) {
          authambercount++;
          authamberamount+=r.amount;
        }
      }
    })

    this.reportData.push({vhcid:this.vhcrecord.id,rtype:'RecordRevenueAuthorizedRed',authorized:true,moment:this.CurrentDate,name:'',amount:authredamount,assignee:""})
    this.reportData.push({vhcid:this.vhcrecord.id,rtype:'RecordRevenueAuthorizedAmber',authorized:true,moment:this.CurrentDate,name:'',amount:authamberamount,assignee:""})
    this.reportData.push({vhcid:this.vhcrecord.id,rtype:'RecordRevenueIdentifiedRed',authorized:true,moment:this.CurrentDate,name:'',amount:identifiedredamount,assignee:""})
    this.reportData.push({vhcid:this.vhcrecord.id,rtype:'RecordRevenueIdentifiedAmber',authorized:true,moment:this.CurrentDate,name:'',amount:identifiedamberamount,assignee:""})
    this.reportData.push({vhcid:this.vhcrecord.id,rtype:'RecordItemsAuthorizedRed',authorized:true,moment:this.CurrentDate,name:'',amount:authredcount,assignee:""})
    this.reportData.push({vhcid:this.vhcrecord.id,rtype:'RecordItemsAuthorizedAmber',authorized:true,moment:this.CurrentDate,name:'',amount:authambercount,assignee:""})
    this.reportData.push({vhcid:this.vhcrecord.id,rtype:'RecordItemsIdentifiedRed',authorized:true,moment:this.CurrentDate,name:'',amount:identifiedredcount,assignee:""})
    this.reportData.push({vhcid:this.vhcrecord.id,rtype:'RecordItemsIdentifiedAmber',authorized:true,moment:this.CurrentDate,name:'',amount:identifiedambercount,assignee:""})

    await this.supa.supabase.from('reportdata').insert(this.reportData, { returning: 'minimal' });
  }

  appendFollowupItemsFromModules(modules: Vhcmodule[], steptxt:string, moduleAssignee:string) {
    const jobsPartsPrice  = (m:any)=>{return m.jobs.map(job=>job.price).reduce((s, c)=>s+c,0)+m.parts.map(part=>part.price).reduce((s, c)=>s+c,0)}
    let assignee        = `Something went wrong in fetching employee. For '${moduleAssignee}'`;
    try{
      assignee        = moduleAssignee!=""?this.vhcrecord.assignees[moduleAssignee][0]?.name:"";
    }catch(e){}
    modules.forEach(vhcmodule => {
      if (vhcmodule.type == "Questionblock") {
        (vhcmodule as VhcQuestionblockModule).questions.forEach(question => {
          if (question.value == 1 || question.value == 2) {
            this.JPReport(question.name, jobsPartsPrice(question), !!question.dateAuthorised, "module", question.value==1, steptxt, assignee)
            question.jobs.forEach(j => this.JPReport(j.name,j.price,!!question.dateAuthorised, "job", question.value==1, steptxt, assignee))
            question.parts.forEach(p => this.JPReport(p.name,p.price,!!question.dateAuthorised, "part", question.value==1, steptxt, assignee))
            if (!question.dateAuthorised) {
              this.followUpItems.push(this.convertItemToFollowUp(question, vhcmodule.getName()))
            }
          }
        })
      } else if (vhcmodule.type == "Tyres") {
        (vhcmodule as VhcTyreModule).data.tyres.forEach(tyre => {
          if (tyre.value == 1 || tyre.value == 2) {
            this.JPReport(tyre.name, jobsPartsPrice(tyre), !!tyre.dateAuthorised, "module", tyre.value==1, steptxt, assignee)
            tyre.jobs.forEach(j => this.JPReport(j.name,j.price,!!tyre.dateAuthorised, "job", tyre.value==1, steptxt, assignee))
            tyre.parts.forEach(p => this.JPReport(p.name,p.price,!!tyre.dateAuthorised, "part", tyre.value==1, steptxt, assignee))
            if (!tyre.dateAuthorised) {
              this.followUpItems.push(this.convertItemToFollowUp(tyre, vhcmodule.getName()))
            }
          }
        })
      } else if (vhcmodule.type == "Brakes") {
        (vhcmodule as VhcBrakeModule).data.brakes.forEach(brake => {
          if (brake.value == 1 || brake.value == 2) {
            this.JPReport(brake.name, jobsPartsPrice(brake), !!brake.dateAuthorised, "module", brake.value==1, steptxt, assignee)
            brake.jobs.forEach(j => this.JPReport(j.name,j.price,!!brake.dateAuthorised, "job", brake.value==1, steptxt, assignee))
            brake.parts.forEach(p => this.JPReport(p.name,p.price,!!brake.dateAuthorised, "part", brake.value==1, steptxt, assignee))
            if (!brake.dateAuthorised) {
              this.followUpItems.push(this.convertItemToFollowUp(brake, vhcmodule.getName()))
            }
          }
        })
      } else if (vhcmodule.type == "Cross_sell") {
        (vhcmodule as VhcCross_sellModule).data.forEach(pckg => {
          if (pckg.value == 1 || pckg.value == 2) {
            this.JPReport(pckg.name, jobsPartsPrice(pckg), !!pckg.dateAuthorised, "module", pckg.value==1, steptxt, assignee)
            pckg.jobs.forEach(j => this.JPReport(j.name,j.price,!!pckg.dateAuthorised, "job", pckg.value==1, steptxt, assignee))
            pckg.parts.forEach(p => this.JPReport(p.name,p.price,!!pckg.dateAuthorised, "part", pckg.value==1, steptxt, assignee))
            if (!pckg.dateAuthorised) {
              pckg.value = 2
              this.followUpItems.push(this.convertItemToFollowUp(pckg, vhcmodule.getName()))
            }
          }
        })
      }
    })
  }

  convertItemToFollowUp(item, category):FollowUpItem {
    var date = new Date(); // Now
    date.setHours(12);
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);
    date.setDate(date.getDate() + (item.value == 2 ? this.settings.amberdays : this.settings.reddays));

    return {
      category: category,
      name: item.name,
      name2: item.name2,
      recommendation: item.recommendation+"",
      recommendation2: item.recommendation2+"",
      value: item.value == 2 ? 'Amber' : 'Red',
      date: date,
      status: 'deferred',
      assignees: []
    }
  }

  async chooseDateTime(item) {
    item.date = (await this.helper.chooseDateTime({IsRange: false, WithTime: false, SingleDate: DateTime.fromJSDate(item.date)}) as DateTime).toJSDate();
  }

  async assignEmp(arr) {
    let result = await this.helper.assignEmployeeToArray('', arr);
  }

  @ConfirmDelete("Do you want to unassign this employee?")
  async removeEmp(arr, index) {
    const eid = arr[index].id;
    arr.splice(index, 1)
  }
  
  dismissModal() {
    this.modalController.dismiss();
  }

  @ConfirmDelete('Locks the record and schedules follow up items if needed. This is not reversible.','Do you want to continue?')
  async save() {
    let newFUD: followupdata = {
      ambercount: 0,
      redcount: 0,
      islang1: this.islang1,
      items: this.followUpItems,
      vhcrecord: this.vhcrecord.id,
      vin: this.vhcrecord.vin,
      remarks: this.remarks,
      history: {
        entries: [{
          date: new Date(),
          description: "Follow up report created",
          employeeuid: this.supa.LoginObject.value.id
        }]
      },
    }
    this.followUpItems.forEach(i => {
      if (i.value == 'Red') {
        newFUD.redcount++;
      } else {
        newFUD.ambercount++;
      }
    });

    let loadr = await this.loadingController.create({ duration: 0, animated: true, showBackdrop: true })
    loadr.present();

    let followupsaved = false;
    if (newFUD.items.length > 0) {
      let followupResult = await this.supa.supabase.from('followup').insert(newFUD, { returning: 'minimal' })
      if (followupResult.error) {
        console.log(followupResult.error);
        this.helper.notify("Could finalize the record / save the follow up items", 'danger')
        loadr.dismiss();
        return
      } else {
        followupsaved = true;
      }
    }
  
    this.supa.supabase.from('vhcrecord').update({ isdone: true, completedrecord: new Date() }, { returning: 'minimal' }).eq("id", this.vhcrecord.id).then(v => {
      loadr.dismiss();
      if (v.error) {
        if (followupsaved) {
          this.helper.notify("Record could not be finalized, but followup is already saved!", "danger")
        } else {
          this.helper.notify("Record could not be finalized!", "danger")
        }
      } else {
        this.helper.notify("Record was finalized", "success")
        this.doReport();
        this.modalController.dismiss();
        this.router.navigate(["home"])
      }
    })
    
    loadr.dismiss();
    this.modalController.dismiss({completestep: true});
  }

  CurrentDate=new Date();
  JPReport(name:string,amount:number,authorized:boolean,recordType:string,isred=false,steptxt:string, assignee:string) {
    this.reportData.push({
      rtype: recordType+steptxt+(isred?'Red':'Amber'), 
      vhcid: this.vhcrecord.id, 
      moment: this.CurrentDate, 
      amount: amount, 
      authorized: authorized, 
      name: name,
      assignee:assignee
    })
  }
}

export interface ReportData {
  id?:number,
  vhcid: number,
  moment: Date,
  rtype: string,
  amount: number,
  name: string,
  authorized: boolean,
  assignee:string
}